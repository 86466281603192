import React, {useState} from "react"
import styled from "styled-components";
import {
  Accordion,
} from 'react-accessible-accordion';
import CustomAccordionItem from "./CustomAccordionItem.js";
import ContainerSmall from "../styles/ContainerSmall";
import Performance from "../icons/performance.svg";
import Accessibility from "../icons/accessibility.svg";
import Speed from "../icons/speed.svg";
import BristolCCPerformance from "../content/comparison-tests/bristol-clean-catalog-lighthouse-2022-02-16.png";
import BristolCCAccessibility from "../content/comparison-tests/bristol-clean-catalog-wave-2022-02-16.png";
import BristolCCSpeed from "../content/comparison-tests/bristol-clean-catalog-pingdom-2022-02-16.png";
import PointLomaAcalogPerformance from "../content/comparison-tests/point-loma-acalog-lighthouse-2022-02-16.png";
import PointLomaAcalogAccessibility from "../content/comparison-tests/point-loma-acalog-wave-2022-02-16.png";
import PointLomaAcalogSpeed from "../content/comparison-tests/point-loma-acalog-pingdom-2022-02-16.png"
import StrayerSCPerformance from "../content/comparison-tests/strayer-smart-catalog-lighthouse-2022-02-16.png";
import StrayerSCAccessibility from "../content/comparison-tests/strayer-smart-catalog-wave-2022-02-16.png";
import StrayerSCSpeed from "../content/comparison-tests/strayer-smart-catalog-pingdom-2022-02-16.png";

const FeaturesInterior = styled.div`
  background: #FFF;
  box-shadow: ${props => props.theme.boxShadow};
  // Overlay resources section
  position: relative;
  z-index: 10;
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 100px;
  @media(min-width: ${props => props.theme.screenSm}) {
    padding-top: 80px;
    padding-left: 110px;
    padding-right: 110px;
  }
`;

const ComparisonAccordion = () => {

  const [uuid, setUuid] = useState(1);


  return (

    <FeaturesInterior>
      <ContainerSmall>
        <Accordion
          allowZeroExpanded="1"
          preExpanded="1"
          onChange={(uuid) => setUuid(uuid)}>
          <CustomAccordionItem
            uuid={1}
            currentUuid={uuid}
            headingText={'Performance Comparison'}
            icon={<Performance/>}
            comparisonPanelText={'Lighthouse, a Google tool, measures a site for performance, accessibility, web best practices, and SEO.'}
            cleanCatalogImg={BristolCCPerformance}
            acalogImg={PointLomaAcalogPerformance}
            smartCatalogImg={StrayerSCPerformance}
            lastUpdated={'Table last updated January 2024'}
          />
          <CustomAccordionItem
            uuid={2}
            currentUuid={uuid}
            headingText={'Accessibility Comparison'}
            icon={<Accessibility/>}
            comparisonPanelText={'Accessibility audit ran with WAVE accessibility tool on catalog homepages from each provider.'}
            cleanCatalogImg={BristolCCAccessibility}
            acalogImg={PointLomaAcalogAccessibility}
            smartCatalogImg={StrayerSCAccessibility}
            lastUpdated={'Table last updated January 2024'}
          />
          <CustomAccordionItem
            uuid={3}
            currentUuid={uuid}
            headingText={'Speed Comparison'}
            icon={<Speed/>}
            comparisonPanelText={'Speed test done with Pingdom Website Speed Test on catalog homepages from each provider.'}
            cleanCatalogImg={BristolCCSpeed}
            acalogImg={PointLomaAcalogSpeed}
            smartCatalogImg={StrayerSCSpeed}
            lastUpdated={'Table last updated January 2024'}
          />

        </Accordion>
      </ContainerSmall>
    </FeaturesInterior>
  )
}

export default ComparisonAccordion;
