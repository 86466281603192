import React from "react"

import styled from "styled-components";

import 'react-tabs/style/react-tabs.css';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Collapse from "../icons/collapse.svg";
import Expand from "../icons/expand.svg";

const ComparisonButton = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #000;
  justify-content: space-between;

  svg {
    padding-right: 1rem;
  }
`

const ComparisonPanel = styled(AccordionItemPanel)`
  padding-top: 1rem;
`

const HeadingText = styled.h3`
  font-size: 2.1em;
  font-weight: 300;
  letter-spacing: 3px;
`

const Column = styled.div`
  width: 33%;
  padding: 1rem;
  max-width: 450px;
  @media (max-width: ${props => props.theme.screenMd}) {
    width: 50%;
  }
  @media (max-width: ${props => props.theme.screenSm}) {
    width: 95%;
  }
`

const Columns = styled.div`
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
  @media (max-width: ${props => props.theme.screenMd}) {
    flex-direction: column;
    align-items: center;
  }
`

const LastUpdated = styled.div`
  margin-top: 1rem;
  font-style: italic;
`

const Provider = styled.h3`
  font-weight: 800;
`

const School = styled.div``

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const ComparisionImg = styled.img`
  width: 100%;
  height: auto;
  box-shadow: ${props => props.theme.boxShadow};
`

const IntroText = styled.div`
  font-size: 1.2em;
`

const CustomAccordionItem = (props) => {


  // Determine the icon
  let icon = <Expand />;
  console.log(props);
  if(props.currentUuid === props.uuid) {
    icon = <Collapse />;
  }


  return (
    <AccordionItem
      uuid={props.uuid}
    >
      <AccordionItemHeading>
        <ComparisonButton>
          <HeadingText>
            {props.icon}
            {props.headingText}
          </HeadingText>
          {icon}
        </ComparisonButton>
      </AccordionItemHeading>
      <ComparisonPanel>
        <IntroText>{props.comparisonPanelText}</IntroText>
        <Columns>
          <Column>
            <HeaderWrapper>
              <Provider>Clean Catalog</Provider>
              <School>Audit of <a href="https://catalog.wvc.edu">WVC</a></School>
            </HeaderWrapper>
            <ComparisionImg src={props.cleanCatalogImg} />
            {props.cleanCatalogData}
          </Column>
          <Column>
            <HeaderWrapper>
              <Provider>Acalog</Provider>
              <School>Audit of <a href="https://catalog.clemson.edu/">Clemson University</a></School>
            </HeaderWrapper>
            <ComparisionImg src={props.acalogImg} />
            {props.acalogData}
          </Column>
          <Column>
            <HeaderWrapper>
              <Provider>Smart Catalog</Provider>
              <School>Audit of <a href="https://strayer.smartcatalogiq.com/en/2021-2022/Catalog">Strayer U</a></School>
            </HeaderWrapper>
            <ComparisionImg src={props.smartCatalogImg} />
            {props.smartCatalogData}
          </Column>
        </Columns>
        <LastUpdated>{props.lastUpdated}</LastUpdated>
      </ComparisonPanel>
    </AccordionItem>

  )
}

export default CustomAccordionItem;
